<template lang="">
  <div
    v-if="
      user.permissions.indexOf(permissionsAll.brand_list) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <CardBrands />
  </div>
</template>
<script>
import { CardBrands } from "../../components/brands";
import permissions from "../../helpers/permissions";
import { mapState } from "vuex";
export default {
  name: "BrandsView",
  components: {
    CardBrands,
  },
  data() {
    return {
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
